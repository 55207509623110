/**
 * Our SocialLinks-block
 *
 * @author David Faber
 * @author Ahmed Alyasiri
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './contact.module.scss';
import Obfuscate from 'react-obfuscate';
import ResponsiveImage, {ResponsiveImageProps} from 'components/ResponsiveImage';
import className from 'classnames';
import {ButtonLink, ButtonLinkProps} from 'components/Button';
import Block from 'components/Block';

const Contact = ({image, company, street, city, country, email, telephone, hasButton, button, ...props}) => (
    <Block className={styles.contact} {...props}>
        <div className={styles.imageLink}>
            <ResponsiveImage className={styles.image} image={image}/>
        </div>
        <div className={styles.contactInfo}>
            <h2 className={styles.company} dangerouslySetInnerHTML={{__html: company}}/>
            <p className={styles.address}>
                {street}<br/>
                {city}<br/>
                {country}
            </p>
            {telephone && <Obfuscate className={styles.infoItem} tel={telephone} rel={'noopener nofollow'}/>}
            <Obfuscate className={styles.infoItem} email={email} rel={'noopener nofollow'}/>
            {hasButton && <ButtonLink link={button} extraClasses={styles.button}/>}
        </div>
    </Block>
);

Contact.propTypes = {
    image: PropTypes.shape(ResponsiveImageProps).isRequired,
    mapsLink: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string,
    email: PropTypes.string.isRequired,
    telephone: PropTypes.string,
    hasButton: PropTypes.bool,
    button: PropTypes.shape(ButtonLinkProps),
};

Contact.defaultProps = {
    country: '',
    telephone: '',
    hasButton: false,
    button: {},
};

export default Contact;
